.leaflet-container {
    width: 100%;
    height: 100%;
}
.journey_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    background: white;
}
.jouney_left {
    width: 20%;
    min-width: 250px;
    height: auto;
    min-height: 100%;
    max-height: 100vh;
    background: #929292;
    padding: 1rem;
    border-right: 2px solid #ccc;
}
.journey_right {
    width: 80vw;
    height: 100vh;
    overflow: hidden;
}
.journey_control_top {
    width: 100%;
    height: auto;
    max-height: 23%;
    padding-bottom: .5rem;
}
.journey_control_bottom {
    width: 100%;
    height: 78%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
.journey_control_bottom::-webkit-scrollbar {
    display: none;
}
label {
    color: #fafafa;
}
.top_coords {
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    color: white;
}
.btn_wide {
    width: 100%;
}
.selection {
    display: flex;
    margin-top: .5rem;
    align-items: center;
    justify-content: space-between;
}
.selection .divider {
    width: 100%;
    padding: .5rem;
    text-align: center;
    cursor: pointer;
}
.selection .selected {
    border-bottom: 2px solid #ddd;
}
.location_wrapper {
    padding: .5rem;
    background: #ccc;
    border-radius: 5px;
    margin-top: 1rem;
    cursor: pointer;
    box-shadow: 0 0 8px rgba(0, 0, 0, .4);
}
.location_wrapper h3 {
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: .5rem;
    border-bottom: 1px solid #444;
    color: #226dbd;
}
.popup_marker {
    cursor: pointer;
    color: #226dbd;
    margin: 0;
}
.location_modal {
    width: 60vw;
    min-width: 1000px;
    height: auto;
    min-height: 60vh;
    background: #dcdcdc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-radius: 10px;
    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}
.location_modal .head {
    width: 100%;
    height: auto;
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 0 0 ;
}
.location_modal .head h1 {
    padding: 1rem;
    background: rgba(255, 255, 255, .6);
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.location_modal .head h1 p {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
    color: #226dbd;
}
.stars span {
    color: #c1a301;
    margin-right: .25rem;
}
.sp-left span:first-child {
    margin-left: 1rem;
}
.location_info {
    width: 100%;
    padding: 1rem;
    height: auto;
}
.location_info h3, .location_info h4 {
    font-weight: 400;
}
.display_quill {
    width: 100%;
    height: auto;
    padding: 1rem 0;
    float: left;
    min-height: 150px;
    padding: 1rem;
    background: #e4e4e4;
}
.display_quill ul li {
    margin-left: 2rem;
}
.data_float {
    width: 50%;
    float: left;
    height: auto;
    min-height: 75px;
    padding-bottom: .5rem;
}
.google_album_buton {
    display: flex;
    align-items: center;
    border: 2px solid #226dbd;
    padding: .5rem 1rem;
    border-radius: 100px;
    cursor: pointer;
}
.google_album_buton span {
    margin-left: 1rem;
    color: #226dbd;
}
.t_center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.google_album_buton img {
    height: 35px;
    width: auto;
}
.object_view {
    width: 100%;
    height: auto;
    padding-left: .5rem 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.viewButton {
    cursor: pointer;
    margin-left: 1rem;
}
.activeView {
    border-bottom: 1px solid #226dbd;
}
.profile {
    width: 100%;
    border: 2px solid #ccc;
    padding: .5rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.profile .user span{
    font-size: .8rem;
    margin-left: 1rem;
}
.profile .logout {
    cursor: pointer;
}
.stopItem {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}
.stopItem input {
    width: 25px;
    margin-right: 1rem;
}
.modal_divider {
    width: 50%;
    float: left;
    min-height: 100px;
}
.pd-25 {
    padding: .5rem;
}
.stopItem label {
    color: #226dbd;
}
.route_item_draggable {
    padding: .25rem;
    color: #226dbd;
    text-transform: uppercase;
}
.route_item_draggable:hover, .route_item_draggable:active {
    background:  #226dbd;
    color: white;
}
.divider_max {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
}
.divider_max::-webkit-scrollbar {
    display: none;
}
.shares_row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .5rem;
    background: #929292;
    border-radius: 5px;
    margin-top: .5rem;
    color: white;
    float: left;
    margin-right: 1rem;
}
.shares_row input {
    width: 15px;
    height: 15px;
    margin-right: .5rem;
}