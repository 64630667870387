.login-image {
    width: 100%;
    height: 100%;
    background-image: url('../images/main_jetti.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.login-wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    background-color: rgba(246, 243, 239, .6);
    backdrop-filter: blur(8px);
    place-items: center;
}

.login {
    width: 350px;
    height: 500px;
    background: white;
    padding: 1rem;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 40%);
}
.login h2 {
    width: 100%;
    text-align: center;
    font-weight: 100;
    text-transform: uppercase;
    border-bottom: 2px solid rgba(246, 243, 239, 1);
}
.form-wrapper {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
}
.form-wrapper input {
    background-repeat: no-repeat;
    background-size: 15px;
    padding: .75rem;
    padding-left: 2rem;
    background-position: center left .5rem;
    outline: none;
    border: none;
    background-color: #fafafa;
    border: 2px solid #dddada;
    border-radius: 10px;
}
.form-wrapper input[aria-label="user"] {
    background-image: url('https://static.thenounproject.com/png/19085-200.png');
}
.form-wrapper input[aria-label="password"] {
    background-image: url('https://icons-for-free.com/iconfiles/png/512/lock+password+protect+safety+security+icon-1320086045132546966.png');
}
.form-wrapper input:active, .form-wrapper input:focus {
    border: 2px solid #123c69;
}
.login label span {
    color: #444;
}
.login img {
    width: 100px;
    border-radius: 50%;
}