:root {
	--main-blue: #2f5779;
	--main-white: #f9f9f7;
}
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
body {
    background-image: url(../images/main_jetti.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}
.main {
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    position: absolute;
    overflow-y: auto;
    top: 0;
    scrollbar-width: none;
}
.main::-webkit-scrollbar {
    display: none;
}
section {
    width: 100%;
    height: auto;
    min-height: 80%;
    padding: 10%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.main_top {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    height: 100vh;
    position: relative;
    font-size: 2rem;
}
.main_top svg {
    display: block;
    position: absolute;
    bottom: -1px;
    left: 0;
}
.filled {
    background: var(--main-blue);
}
.main_top h1 {
    font-size: 6rem;
    font-weight: 100;
}
.main_top p {
    background: var(--main-blue);
    padding: 1rem 2rem;
    border-radius: 100px;
}
.wavy_second {
    background: var(--main-white);
}
.section_two {
    background: #fff;
}
.task {
    text-transform: UPPERCASE;
    color: #FFF;
    letter-spacing: 4px;
    font-size: 3rem;
}
.design_part {
    width: 35%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 3rem 0
}
.design_icon {
    font-size: 7rem;
    color: white;
}
.design_part p {
    font-size: 1.8rem;
    opacity: 0;
    transition: opacity .5s ease;
}
.t_blue p {
    color: var(--main-blue);
}
footer {
    width: 100%;
    height: 200px;
    background: var(--main-blue);
    padding: 2rem;
    text-align: center;
    color: white;
}
.rounded {
    border-radius: 15px;
}
.opac {
	opacity: 1 !important;
}


@media screen and (max-width: 1366px) {
    .main_top h1 {
        font-size: 3rem;
    }
    .main_top {
        font-size: 1rem;
    }
    .task {
        font-size: 1.5rem;
    }
    .design_part p {
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 1024px) {
    .design_part {
        width: 45%;
    }
}
@media screen and (max-width: 768px) {
    section {
        flex-direction: column;
    }
    .section_two {
        flex-direction: column-reverse;
    }
}
@media screen and (max-width: 612px) {
    .design_part {
        width: 100%;
    }
    section {
        padding: 10% 20%;
    }
}
@media screen and (max-width: 400px) {
    .design_part {
        width: 100%;
    }
    section {
        padding: 10% 5%;
    }
}