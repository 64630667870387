.data_wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  grid-area: data;
  display: grid;
  grid-template-rows: calc(100% - 201px - 1rem) 201px;
  row-gap: 1rem;
}
.dataTable {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  scrollbar-width: 0;
}
.dataTable::-webkit-scrollbar {
  display: none;
}
table {
  width: 100%;
  margin: 0 auto;
  background-color: #ffffff;
  font-size: 1rem;
  border-collapse: collapse;
}
table th,
table td {
  text-align: left;
  padding: 15px;
  box-sizing: border-box;
}
table th {
  color: #ffffff;
  background-color: #3a2b2b;
  border-bottom: solid 2px #d8d8d8;
  position: sticky;
  top: 0;
}
table td {
  border: solid 1px #d8d8d8;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
}
table tbody tr {
  transition: background-color 150ms ease-out;
}
table tbody tr:nth-child(2n) {
  background-color: whitesmoke;
}
table tbody tr:hover {
  background-color: #ebebeb;
}
a {
  text-decoration: none;
  color: inherit;
}
.editMeBtn {
  cursor: pointer;
  text-align: center;
}
.wall {
  border-right: 1px solid #d8d8d8;
}
