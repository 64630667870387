.button-style {
    padding: 1rem 2rem;
    outline: none;
    border: 2px solid #123c69;
    color: white;
    background-color: #123c69;
    margin: 1rem 0;
    cursor: pointer;
    font-weight: 500;
    border-radius: 500px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
}
.button-style:hover {
    color: #123c69;
    background-color: white;
}
button:disabled, input[type="submit"]:disabled {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;
}
.button-loading::after {
    content: "";
    position: absolute;
    display: block;
    width: 0.7em;
    height: 0.7em;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    border: 2px var(--btnTxt);
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-style: solid;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 250ms;
}
.button-loading:disabled::after{
    color: white;
    opacity: 1;
    animation: rotate 750ms linear infinite;
}
.button-loading:disabled:hover::after {
    color: #123c69;
}
.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 2px;
    position: relative;
}
.loading {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #444;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    margin-left: .5rem;
    position: absolute;
}
.mr-5 {
    margin-right: 1rem;
}
button > span {
    margin-left: 1rem;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}