* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}
:root {
  font-size: 14px;
  --main-black: #444;
  --main-red: rgb(218, 40, 40);
}

body {
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: block;
}

.App {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: inline;
  position: relative;
}

.input input {
  background: #fcfcfc;
  padding: 0.46428571em;
  border-radius: 6px;
  border: 1px solid #ececec;
  margin-top: 0.5rem;
  padding: 0.5rem;
}
.input input:focus {
  border: 1px solid rgb(133, 206, 255);
}
label {
  text-transform: uppercase;
  color: #888;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.error {
  width: auto;
  height: auto;
  padding: 2rem;
  white-space: normal;
  max-width: 350px;
  background: rgb(247, 66, 66);
  color: white;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9000;
  border-radius: 10px 10px 0 0;
}
.backdrop {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  background: rgba(186, 178, 181, 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9000;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 3rem;
  scrollbar-width: none;
}
.backdrop::-webkit-scrollbar {
  display: none;
}
.my-modal {
  width: 50%;
  height: auto;
  min-height: 500px;
  background: #dcdcdc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
}
.my-modal h1 {
  font-weight: 100;
  margin-bottom: 1rem;
  color: #123c69;
}
.input {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #123c69;
  margin-bottom: 0.25rem;
}
.input input,
.input select {
  width: 100%;
  margin: 0;
}
select {
  padding: 0.75rem;
  border: 5px;
}
.row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1rem;
}
.input span,
.route_item_header {
  font-size: 0.9rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
}
form {
  width: 100%;
  height: auto;
}
.input_quill {
  width: 100%;
  background: white;
  margin-top: 1rem;
  height: auto;
  min-height: 250px;
  display: table;
}
.input_quill .tellme {
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 400;
  padding: 0.5rem;
  color: #123c69;
}
.ql-editor {
  min-height: 200px;
}
.ql-formats {
  font-size: 1rem;
}
.alert {
  width: 100%;
  padding: 1rem;
  background: rgb(255, 153, 153);
  border-radius: 5px;
  border: 1px solid rgb(201, 81, 81);
  color: rgb(201, 81, 81);
}

@media screen and (max-width: 1024px) {
  :root {
    font-size: 12px;
  }
}
