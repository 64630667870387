nav {
    width: 100%;
    height: 85px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: background .5s ease;
    transition-delay: 200ms;
}
.logo {
    height: 70px;
    width: auto;
}
.lang {
    height: 20px;
    width: auto;
    margin-left: 1rem;
    cursor: pointer;
}
.nav_filler {
    background: #444;
}
.logo_wrapper {
    width: 0px;
    height: 0px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: width .4s ease, height .4s ease;
    transition-delay: 0;
}
.logo_popper {
    width: 125px;
    height: 125px;
}
.login_to_travel {
    width: 85px;
    height: 50px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 100px;
    cursor: pointer;
    border: 2px solid #226dbd;
    overflow: hidden;
    position: relative;
}
.login_to_travel img {
    height: 25px;
    width: auto;
}
.travel_inner {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 30px;
    transition: right .5s ease-in-out;
}
.travel_inner span {
    white-space: nowrap;
    margin-right: 3rem;
}
.login_hovered {
    right: calc(-200% - 65px);
}