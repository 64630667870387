.reskontra {
  width: 100%;
  height: 100%;
  position: relative;
  background: #bab2b5;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 85px calc(100% - 85px);
  padding: 0 200px;
  grid-template-areas:
    "nav"
    "data";
}
nav {
  width: 100%;
  height: 100%;
  display: grid;
  padding: 0 25vw;
  grid-template-columns: 1fr 1fr 1fr;
  background: #eee2dc;
  grid-area: nav;
}
.nav_btn {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 1.3rem;
  background: #123c69;
  border-radius: 8px;
  color: #eee2dc;
  cursor: pointer;
}
.nav_btn span {
  margin-left: 1rem;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 100;
  letter-spacing: 2px;
}
.bookings_wrapper {
  width: 100%;
  height: auto;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  background-color: #efefef;
  border-radius: 10px;
  margin-top: 2rem;
}
.bookings_wrapper > h3 {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-column-end: 3;
}
.deleteModal {
  width: 500px;
  height: auto;
  border-radius: 10px;
  background-color: #efefef;
  padding: 1rem;
}
.deleteItem {
  cursor: pointer;
  color: var(--main-red);
}
.year-selector {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
}
.year-selector > select {
  border-radius: 5px;
}
.filter {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 0.5rem 1rem;
  column-gap: 0.5rem;
  background-color: #ccc;
}
.filter > div {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.filterAll {
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  background-color: var(--main-blue);
  color: var(--main-white);
  width: max-content;
  cursor: pointer;
  place-self: center;
}
.deleteInfo {
  padding: 1rem 0;
}
.deleteInfo > input {
  width: 100%;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  margin-top: 0.5rem;
}
