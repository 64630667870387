.trips-modal {
    width: 80%;
    height: 90%;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .5);
}
.trips-modal::-webkit-scrollbar {
    display: none;
}
.trips-modal--top, .trips-modal--center, .trips-modal--bottom {
    width: 100%;
    height: auto;
    padding: 1rem
}
.trips-modal--top {
    padding: 1rem;
}.trips-modal--top h1 {
    font-weight: 200;
    color: #123c69;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.trips-modal--center {
    width: calc(100% - 2rem);
    height: auto;
    border: 2px solid #ccc;
    margin: 0 1rem;
    min-height: 150px;
    padding: 1rem;
}
.trips-modal--bottom {
    background: white;
    min-height: 100px;
    padding: 1rem;
}
.place {
    width: 50%;
    height: auto;
    display: flex;
    background: #fafafa;
    box-shadow: 0 0 5px rgba(0, 0, 0, .6);
    border-radius: 5px;
    position: relative;
    margin-bottom: 4rem;
    cursor: pointer;
    transition: width .5s ease;
}
.place:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, .6);
}
.place-arrow{
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 50%;
    color: #226dbd;
    z-index: 100;
    font-size: 2rem;
    opacity: 0;
    transition: 
        top .5s ease,
        left .3s ease;
}
.place-arrow:last-of-type {
    color: #226dbd;
}
.place:hover .place-arrow {
    opacity: 1;
    bottom: -3rem;
    transition: bottom .5s;
}

.place-info {
    width: 100%;
    height: auto;
    padding: 1rem;
}
.place-info h3 {
    font-weight: 400;
    text-transform: uppercase;
    color: #226dbd;
}
.place img {
    width: 50%;
    height: auto;
    border-radius: 5px 0 0 5px
}
.place-text {
    margin-top: 1rem;
}
.blue {
    color: #226dbd;
}
.toggled-place {
    width: 100%;
}
.toggled-place .place-arrow {
    left: 25% !important;
}
.place-normally-hidden {
    width: 100%;
    margin-top: 1rem;
    color: transparent;
    height: 0;
}
.toggled-place .place-normally-hidden {
    transition: color .3s;
    transition-delay: .5s;
    height: auto;
    min-height: 150px;
    color: #226dbd;
}